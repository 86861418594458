<template>
  <div>
    <member-breadcrumb :title="title"/>
    <div>
      <el-form :inline="true" :model="query" class="pull-left" onsubmit="return false">


        <el-form-item label="状态">
          <el-select v-model="query.flag" placeholder="请选择">
            <el-option
                    key=""
                    label="全部"
                    value="">
            </el-option>
            <el-option
                    v-for="(name,value) in InvoiceFlagMap"
                    :key="value"
                    :label="name"
                    :value="value">
            </el-option>
          </el-select>

        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="pagingSearch">查询</el-button>
        </el-form-item>
      </el-form>

      <div class="clear"></div>
    </div>


    <el-table
            :data="data.list"
            border
            class="mt10"
            @sort-change="elSort"
            :default-sort="{prop: 'id', order: 'descending'}"
            style="width: 100%">


      <el-table-column
              prop="id"
              align="center"
              label="申请"
              width="160"
              sortable="custom" :sort-orders="[ 'descending','ascending']"
      >
        <template slot-scope="scope">
          {{dateUtil.format(scope.row.createdAt)}}
        </template>
      </el-table-column>


      <el-table-column
              prop="companyName"
              align="center"

              label="抬头"
      />

      <el-table-column
              prop="tradeMoney"
              align="right"
              header-align="center"
              width="100"
              label="金额"
      >
        <template slot-scope="scope">
          {{scope.row.tradeMoney/ 100}}
        </template>
      </el-table-column>


      <el-table-column
              prop="type"
              align="center"
              label="类型"
              width="100"
      >
        <template slot-scope="scope">
          {{InvoiceTypeMap[scope.row.type.toString()]}}
        </template>
      </el-table-column>

      <el-table-column
              prop="type"
              align="center"
              label="状态"
              width="100"
      >
        <template slot-scope="scope">
          {{InvoiceFlagMap[scope.row.flag.toString()]}}
        </template>
      </el-table-column>


      <el-table-column
              align="center"
              label="操作"
              width="100"
      >
        <template slot-scope="scope">
          <router-link :to="{name:'invoiceDetail', params:{id:scope.row.id}}">查看</router-link>
        </template>
      </el-table-column>

    </el-table>
    <my-pagination :pager="data" @goPage="pagingTurn"></my-pagination>
  </div>
</template>

<script>
  import urlUtil from 'tdw-url-util'
  import dateUtil from 'tdw-date-util'
  import MyPagination from '../common/MyPagination'
  import InvoiceFlagMap from './InvoiceFlagMap'
  import InvoiceTypeMap from './InvoiceTypeMap'
  import MemberBreadcrumb from '../common/MemberBreadcrumb'

  export default {
    name: 'InvoiceList',
    components: {MemberBreadcrumb, MyPagination},
    data () {
      return {
        title: '发票记录',
        usePagingPlugin: true,
        query: {
          pageNum: urlUtil.getPara('pageNum', 1),
          pageSize: 20,
          orderColumn: 'id',
          orderType: 'desc',
          flag: urlUtil.getPara('flag')
        },
        queryUrl: '/invoice/list.do',
        data: {list: []},
        dateUtil,
        InvoiceFlagMap,
        InvoiceTypeMap
      }
    }
  }
</script>

<style scoped>

</style>